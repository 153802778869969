<template>
    <div>
        <div class="bg-white rounded d-flex align-items-center justify-content-between p-3">
            <div>
                <h5 class="m-0 fw-normal mb-1">Pesquisa de Satisfação</h5>
                <div class="d-flex align-items-center">
                    <div class="text-secondary">
                        Total de Pesquisas
                    </div>
                    <div class="ms-2 bg-purple text-white fw-bold rounded-pill px-3">
                        1000
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex gap-2">
                <b-button variant="success" class="rounded-pill py-1">Todos</b-button>
                <b-button variant="outline-secondary" class="rounded-pill py-1">Hoje</b-button>
                <b-button variant="outline-secondary" class="rounded-pill py-1">7 dias</b-button>
                <b-button variant="outline-secondary" class="rounded-pill py-1">Por Período</b-button>
            </div> -->
            <div class="d-flex flex-column align-items-end  gap-2">
                <div class="d-flex gap-2">
                    <b-form-select v-model="filters.date" class="rounded-pill border-dark-grey px-2 py-1">
                        <b-form-select-option :value="null || undefined">Todos</b-form-select-option>
                        <b-form-select-option value="1">Hoje</b-form-select-option>
                        <b-form-select-option value="2">Esta Semana</b-form-select-option>
                        <b-form-select-option value="3">Este Mês</b-form-select-option>
                        <b-form-select-option value="4">Definir </b-form-select-option>
                    </b-form-select>
                    <b-button variant="success" class="rounded-pill py-1" v-if= "filters.date == 4 && (filters.startDate && filters.endDate)">
                        Filtrar
                    </b-button>
                </div>
                <div class="w-100 d-flex gap-3" v-if="filters.date == 4">
                    <div class="w-50">
                        <b-form-group class="d-flex align-items-center gap-1" label="De:">
                            <b-form-input class="rounded-pill px-2 py-1" type="date" v-model="filters.startDate"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="w-50">
                        <b-form-group class="d-flex align-items-center gap-1" label="Até:">
                            <b-form-input class="rounded-pill px-2 py-1" type="date" v-model="filters.endDate"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white rounded mt-3" v-if="hasSearched">
            <div class="d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                    <div class="h5 mb-0 bg-green text-white rounded-circle" style="padding: 0.5em;">02</div>
                    <h5 class="m-0 ms-2 text-purple">Relatório Listagem de Atendimento</h5>
                </div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Atendimentos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ tableItems.count || 0 }}</div>
                </div>
            </div>
            <div class="border-top">
                <div class="bg-white d-flex justify-content-center p-5" v-if="loadingData">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="tableItems && tableItems.length">
                    <b-table class="reportContacts m-0" tbody-tr-class="align-middle" hover head-variant="dark" :items="tableItems" :fields="tableFields">
                        <template #cell(attendanceEndTime)="data">
                            {{ data.item.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #cell(attendanceStartTime)="data">
                            {{ data.item.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #head(messages)>{{""}}</template>
                        <template #cell(messages)="data">
                            <b-button class="bg-green border-0 px-4" pill @click="loadMessages(data.item._id,data.item.operatorId)" v-b-modal.contactMessages v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento"><b-icon icon="chat-square-text"></b-icon></b-button>
                        </template>
                        <template #cell(clientName)="data">
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.item.photoURL"></b-avatar>
                                </div>
                                <div>
                                    {{ data.item.clientName }}
                                </div>
                            </div>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-between align-items-center px-4 py-3" v-if="tableItems && tableItems.count > 10">
                        <b-pagination class="m-0"
                            :total-rows="tableItems.count"
                            v-model="currentPage"
                            :per-page="perPage"
                            @change="pageChange"
                        ></b-pagination>
                        <span class="pagdescription">
                            <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{(currentPage * perPage > tableItems.count) ? tableItems.count : currentPage * perPage}} de {{ tableItems.count }} atendimentos</div>
                        </span>
                    </div>
                </div>
                <div class="text-center p-3 text-secondary" v-else>
                    Nenhum dado encontrado!
                </div>
            </div>
            <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
                <div>
                    <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
                </div>
            </b-modal>
        </div>
        <FAB :screenshotBtn="true" />
    </div>
</template>

<script>
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import FAB from './FAB.vue'

export default {
    props: [
        'user', 'timestampDiff', 'mediaURL'
    ],
    components: {
        Whatsapp, FAB
    },
    data() {
        return {
            filters: {
                date: 3
            },
            operators: [],
            departments: [],
            tabulations: [],
            chartData: [],
            tableFields: [
                {
                    key: 'clientName',
                    label: 'Contato',
                },
                {
                    key: 'clientNumber',
                    label: 'Telefone'
                },
                // {
                //     key: 'clientName',
                //     label: 'Notificações',
                // },
                {
                    key: 'completionReason',
                    label: 'Tabulação',
                    sortKey: true,
                },
                {
                    key: 'attendanceStartTime',
                    label: 'Data de início do atendimento',
                    sortKey: true,
                },
                {
                    key: 'attendanceEndTime',
                    label: 'Data de fim do atendimento',
                    sortKey: true,
                },
                {
                    key: 'messages',
                    class: ''
                }
            ],
            tableItems: [],
            currentPage: 1,
            perPage: 10,
            contactMessages: [],
            loadingData: false,
            hasSearched: false
        }
    },
    mounted: function() {
        // this.getData()
        this.getDepartments()
        this.getOperators()
        this.getTabulations()
        this.onCloseModal()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.contactMessages = []
            })
        },
        async getData(page = 1) {
            if(!this.tabulations || !this.tabulations.length) {
                this.loadingData = false
                return this.$emit('msg', {
                    text: 'Nenhuma tabulação encontrada!',
                    success: false
                })
            }
            this.loadingData = true
            if(!this.filters.typeId)
                this.filters.type = null
            this.chartData = {}
            const resp = await this.getReportTabulation({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, reason: this.filters.reason, page })
            // console.log('resp report',resp)
            if(resp) {
                this.tableItems = resp
            } else {
                this.tableItems = []
            }
            const resp2 = await this.getReportTabulationCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, reason: this.filters.reason })
            // console.log('resp count',resp2)
            if(resp2) {
                const labels = []
                const values = []

                this.tabulations.map(tab => {
                    values.push({
                        label: tab.text,
                        backgroundColor: utils.generateColor(),
                        data: []
                    })
                })

                await resp2.map(async el => {
                    labels.push(this.$options.filters.date(el.date,'DD/MM/YY',true))
                    if(el.completionReason?.length) {
                        this.tabulations.map(tab => {
                            const count = el.completionReason.filter(x => x == tab.text).length
                            const find = values.find(value => value.label == tab.text)
                            if(find) {
                                find.data.push(count)
                            }
                        })
                    } else
                        values.map(value => value.data.push(0))
                    // values.push(el.count)
                    return el
                })

                this.$set(this.chartData, 'labels', labels)
                this.$set(this.chartData, 'values', values)
            }
            this.loadingData = false
            this.hasSearched = true
            return
        },
        async getReportTabulation(data) {
            const resp = await api.getReportTabulation(data)
            if(resp.statusCode == 200) {
                resp.attendances['count'] = resp.count
                return resp.attendances
            }
            return false
        },
        async getReportTabulationCount(data) {
            const resp = await api.getReportTabulationCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode!=200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode!=200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async loadMessages(attendanceId, operatorId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
                operatorId
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if(this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if(el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200) {
                this.tabulations = resp.tabulations
            }else{
                this.loadingData = false
                this.$emit('msg', {
                    text: 'Nenhuma tabulação encontrada!',
                    success: false
                })
            }
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getData(page)
            }
        }
    },
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
</style>

<style scoped>

</style>